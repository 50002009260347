.hero.is-fullheight{
	-webkit-background-size:cover;
	-moz-background-size:cover;
	-o-background-size:cover;
	background-size:cover
}
.navbar-brand>a:focus,.navbar-brand>a:hover{
	background-color:transparent!important;
	color:#d3d3d3!important
}
.card-footer{
	background-color:rgba(32,32,32,.65);
	color:#fffff7;
	font-size:.75rem;
	border:none
}
.card-footer-item{
	padding:.375rem
}

.show{
	display:block
}
.hide{
	display:none
}
.portrait img {
	max-width:350px;
	display:inline-block;
}

/* Adds spacing between section headings on the "course descriptions" page. Need a better fix for this. */
h2.subtitle {
	margin-top:1.5rem;
}
@media screen and (max-width:935px){
	.navbar-item{
		text-align:center
	}
	.dropdown-menu{
		text-align:left
	}

}
.contact-header{
	font-size:1.2em;
	text-decoration:underline;
	font-weight:500;
	margin-bottom:.25em
}
.is-vertical-center{
	display:flex;
	align-items:center
}
.footerIconParent:hover * i{
	opacity:.5
}
.footerIconParent:hover * i:hover{
	opacity:1
}
/* Let navbar items overflow, so there's no unnecessary scrollbars on mobile screens */
.navbar.is-fixed-top .navbar-menu {
	overflow: visible;
}
.navbar.solid{
	background-color:rgba(32,32,32,.65)!important;
	transition:background-color 1s ease 0s
}

/* Hack to make paragraphs separated with spaces (like in parskip mode) */
.has-vertical-spacing {
	margin-top:0.5em;
	margin-bottom:0.5em;	
}

/* PDFObject support */
.pdfobject-container {
	height:40rem;
}


@charset "utf-8";

// Import Google Fonts
@import url('https://fonts.googleapis.com/css?family=Crimson+Text:400,400i,700|Raleway:400,500');

// Font definitions
$family-sans-serif: "Raleway", sans-serif;
$family-serif: "Crimson Text", serif;
$family-primary: $family-serif;
$family-secondary: $family-sans-serif;
$body-size: 18px;
$title-family: $family-sans-serif;
$subtitle-family: $family-sans-serif;
$title-weight: 500; //I'd like to say $weight-medium, but it doesn't exist yet

// Color customizations
$white: #fffff7;
$primary: rgba(255,255,247,.75);
$text: #202020;
$link: rgba(32,32,32,.75);
//The navbar's color can't be set with any classes when it's used with a fullheight hero; this seems to be the only way to change it
$navbar-background-color: transparent;
$navbar-burger-color: $white;

// HR customizations
$hr-height: 1px;
$hr-margin: .1em 0 .8em;
$hr-background-color: grey;

// Button customizations
$button-family: $family-sans-serif;
$button-focus-box-shadow-size: 0;

// Make "notification" symmetric, since we use it for all body tiles
$notification-padding: 1.25rem 1.5rem 1.25rem 1.5rem;

// Increase size of modal overlays, since they are used for pictures
$modal-content-width: 1200px;